import React, { useState, useEffect } from 'react';
import { Flex, Box, Image, Button, useMediaQuery } from '@chakra-ui/core';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { colors } from '@theme';
import { H4, Text } from '@components/typography';
import { KeyFeatures, Anchor, HelpfulResources, Reviews } from '@personalaccounts';
import {
  APP_CONTENT_WIDE_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
  SMALL_SCREEN,
} from '@styles/sizes';

import ContentContainer from '../components/ContentContainer';
import ZetaLogo from '@assets/ZetaLogo';
import { trackReferral, storeReferralToken } from '../lib/referral/utils';
import { nextUrl } from '../lib/invitations/utils';
import { cloudfrontBaseUrl, PAFAQContent } from '@lib/consts';

import VIP from '../components/jointAccount/VIP';
import CardFeatures from '../components/jointAccount/cardFeatures';
import HowItWorks from '../components/jointAccount/howItWorks';
import FAQs from '../components/jointAccount/FAQs';
import Footer from '../layouts/Footer';

const SoloPassInvite = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [isMobile] = useMediaQuery(`(max-width: ${SMALL_SCREEN})`);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [referrerName, setReferrerName] = useState('');

  const fullslug = pathname.split(/[/ ]+/).pop();
  const slug = fullslug[0] + fullslug.slice(1);

  const track = async () => {
    const referralData = await trackReferral({ slug, offerCode: 'solo_pass' });

    if (
      referralData.referralToken === null ||
      referralData.referrerType === 'Affiliate'
    ) {
      setIsInvalid(true);
      setLoaded(true);
      return;
    }
    storeReferralToken(referralData.referralToken);
    setReferrerName(referralData.referrerName);
    setLoaded(true);
  };

  useEffect(() => {
    track();
  }, []);

  if (!loaded) return null;

  const imageData = {
    desktop: {
      src: `${cloudfrontBaseUrl}/invite/solo-pass-hero-desktop-2.png`,
    },
    mobile: {
      src: `${cloudfrontBaseUrl}/invite/solo-pass-mobile-3.png`,
      width: '305px',
      height: '244px',
    },
  };

  return (
    <Flex direction="column">
      <Flex background={colors.greenPea}>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Flex
            direction={['column', 'row']}
            pt={['32px', '120px']}
            px={['20px', '120px']}
          >
            <Flex
              direction="column"
              justify={['center', 'flex-start']}
              align={['center', 'start']}
            >
              <ZetaLogo />
              {isInvalid ? (
                <Text
                  textAlign={['center', 'start']}
                  fontSize="24px"
                  mt="30px"
                  bold
                  color={colors.white}
                  lineHeight="32px"
                >
                  Sorry, this link appears to be invalid.
                </Text>
              ) : (
                <Text
                  textAlign={['center', 'start']}
                  fontSize="24px"
                  mt="30px"
                  bold
                  color={colors.white}
                  lineHeight="32px"
                >
                  Join{' '}
                  {referrerName === 'The Zeta Team'
                    ? 'us'
                    : referrerName || 'your friend'}{' '}
                  at Zeta by
                  <br />
                  claiming your Solo Pass
                </Text>
              )}
              <Flex
                mt={['13px', '24px']}
                mb={['none', '120px']}
                direction="column"
                align={['center', 'start']}
              >
                {isInvalid ? (
                  <Text
                    textAlign={['center', 'left']}
                    w={['355px', '400px']}
                    size="16px"
                    color={colors.white}
                  >
                    We recommend you check your invite url, feel free to contact us at
                    support@askzeta.com if you need futher assistance.
                  </Text>
                ) : (
                  <>
                    <Text
                      textAlign={['center', 'left']}
                      w={['355px', '400px']}
                      size="16px"
                      color={colors.white}
                    >
                      {referrerName || 'Your friend'} thinks you'll enjoy using Zeta to
                      manage your finances. As our friend's friend, you'll get a exclusive
                      access to open a personal account, which is only available via your
                      secret code.
                    </Text>

                    <Button
                      align={['center', 'none']}
                      h="40px"
                      w="197px"
                      background={colors.yellowLight}
                      mt="24px"
                      onClick={() => {
                        window.location.href = `${nextUrl}?slug=${slug}`;
                      }}
                    >
                      Get started
                    </Button>
                    <Text
                      mt={['1rem', '24px']}
                      pt={2}
                      fontSize="14px"
                      textAlign={['center', 'left']}
                      width="16rem"
                      fontStyle="italic"
                      fontWeight={400}
                      color={colors.white}
                    >
                      By submitting and continuing
                      <br />
                      you agree to the{' '}
                      <a
                        style={{ color: colors.ocean }}
                        target="_blank"
                        href="/referral-terms-and-conditions-solo"
                      >
                        referral agreement
                      </a>
                      .
                    </Text>
                  </>
                )}

                {isMobile && (
                  <Flex direction="column">
                    <Image mt="24px" src={imageData['mobile'].src} />

                    <Flex
                      align="center"
                      pb={['58px', '']}
                      mt={['58px', '']}
                      justify="center"
                    >
                      <Text
                        ml={4}
                        fontStyle="italic"
                        width="225px"
                        color={colors.white}
                        fontSize="10px"
                      >
                        Zeta Joint Account is a deposit account powered by Piermont Bank,
                        Member FDIC.
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>

            {!isMobile && (
              <Flex direction="column">
                <Image w="100%" src={imageData['desktop'].src} />

                <Flex
                  align="center"
                  pb={['58px', '58px']}
                  mt={['58px', '58px']}
                  justify="center"
                >
                  <Text
                    ml={4}
                    fontStyle="italic"
                    width="225px"
                    color={colors.white}
                    fontSize="10px"
                    style={{ textAlign: 'center' }}
                  >
                    Zeta Joint Account is a deposit account powered by Piermont Bank,
                    Member FDIC.
                  </Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </ContentContainer>
      </Flex>
      {!isInvalid && (
        <>
          <Flex background={colors.primary}>
            <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
              <KeyFeatures soloPass />
            </ContentContainer>
          </Flex>

          <VIP />

          <Flex bg={colors.iceberg} className="theres-even-more-to-love">
            <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
              <CardFeatures />
            </ContentContainer>
          </Flex>

          <Reviews />

          <Flex justify="center" className="how-it-works">
            <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
              <HowItWorks variant="solo-pass" />
            </ContentContainer>
          </Flex>

          <Flex bg={colors.background} className="FAQs">
            <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
              <FAQs content={PAFAQContent} />
            </ContentContainer>
          </Flex>

          <HelpfulResources />

          <Anchor getStarted="personal" />
        </>
      )}
      <Footer />
    </Flex>
  );
};

export default SoloPassInvite;
